import { TruckIcon } from "@heroicons/react/outline";
import {
	ClockIcon,
	LocationMarkerIcon,
	PhoneIncomingIcon,
} from "@heroicons/react/solid";
import { AnalyticsContext } from "components/contexts/AnalyticsProvider";
import { useContext } from "react";
import { BsCartPlusFill } from "react-icons/bs";
import { ShopHeader } from "./ShopHeader";
import { useIsInIframe } from "components/hooks/useIsInIframe";
import { FaWaze } from "react-icons/fa";

export function ShopGeneral({ store, isWidget }) {
	const { collectAnalytics } = useContext(AnalyticsContext);
	const isInIframe = useIsInIframe();

	if (!store) {
		return null;
	}

	const address = `${store?.street_name} ${store?.street_num}, ${store?.city.heb_name}`;
	const googleMapsUrl = `https://www.google.com/maps/search/${address}`;
	const wazeUrl = `https://www.waze.com/ul?ll=${store.latitude}%2C${store.longitude}&navigate=yes&zoom=17`;

	return (
		<div className="flex flex-col gap-1.5 lg:text-base text-sm">
			{isWidget && isInIframe && (
				<ShopHeader isWidget={isWidget} store={store} />
			)}
			<a
				href={googleMapsUrl}
				target="_blank"
				rel="noreferrer"
				className="text-jane-700 hover:underline flex items-center w-fit"
				id="__external_click__address"
				onClick={() => {
					collectAnalytics("store_link_google_maps");
				}}
			>
				<LocationMarkerIcon className="h-4 w-4 text-jane-900 opacity-50 ml-2" />
				{address}
			</a>
			<a
				href={wazeUrl}
				target="_blank"
				rel="noreferrer"
				className="text-jane-700 hover:underline flex items-center md:hidden w-fit"
				onClick={() => {
					collectAnalytics("store_link_waze");
				}}
			>
				<FaWaze className="h-4 w-4 text-jane-900 opacity-50 ml-2" />
				ניווט באמצעות וויז
			</a>
			{!!store.phone_number && (
				<div className="flex items-center">
					<PhoneIncomingIcon className="h-4 w-4 text-jane-900 opacity-50 ml-2" />
					<a
						className="text-jane-700 hover:underline"
						id="__external_click__phone"
						href={"tel:" + store.phone_number}
						onClick={() => {
							collectAnalytics("store_link_phone");
						}}
					>
						{store.phone_number}
					</a>
				</div>
			)}
			{!!store.phone_number_2nd && (
				<div className="flex items-center">
					<PhoneIncomingIcon className="h-4 w-4 text-jane-900 opacity-50 ml-2" />
					<a
						className="text-jane-700 hover:underline"
						id="__external_click__phone"
						href={"tel:" + store.phone_number_2nd}
						onClick={() => {
							collectAnalytics("store_link_phone");
						}}
					>
						{store.phone_number_2nd}
					</a>
				</div>
			)}
			{!!store.show_customer_service_hours && (
				<div className="flex items-center">
					<ClockIcon className="h-4 w-4 text-jane-900 opacity-50 ml-2" />
					<div className="text-jane-700 flex gap-1">
						<span>
							{store.customer_service_to_hour} -{" "}
							{store.customer_service_from_hour}
						</span>
						<span>(שעות פעילות מוקד טלפוני)</span>
					</div>
				</div>
			)}
			<div className=" flex items-center ">
				<BsCartPlusFill className="h-4 w-4 text-jane-900 opacity-50 ml-2" />
				<span>איסוף עצמי</span>
			</div>

			{!!store.has_delivery && (
				<div className="flex items-center">
					<TruckIcon className="h-4 w-4 text-jane-900 opacity-50 ml-2" />
					<span>יש משלוחים</span>
					{!!store.delivery_min_amount && (
						<span className="mx-0.5">
							(מינ&apos; {store?.delivery_min_amount * 10} גרם)
						</span>
					)}
				</div>
			)}
		</div>
	);
}

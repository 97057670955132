import { ChevronLeftIcon } from "@heroicons/react/solid";
import { useContext, useEffect, useMemo, useState } from "react";
import { classNames } from "util/common";
import { AnalyticsContext } from "../contexts/AnalyticsProvider";
import useWindowDimensions from "components/hooks/useWindowDimensions";
import { WBO } from "util/types";
import { useIsInIframe } from "components/hooks/useIsInIframe";

export function ShopTabsMenu({
	onChange = t => {},
	store,
	children,
	discountsList,
	isWidget = false,
}) {
	const isInIframe = useIsInIframe();
	const { width } = useWindowDimensions();
	const tabs = [{ value: "hours", label: "שעות פתיחה" }];

	if (isInIframe || (isWidget && width < WBO["md"])) {
		tabs.push({ value: "general", label: "מידע כללי" });
	}
	if (discountsList.length > 0) {
		tabs.unshift({ value: "sales", label: `מבצעים (${discountsList.length})` });
	}
	if (store?.has_delivery) {
		tabs.push({ value: "delivery", label: "משלוחים" });
	}

	const [activeTab, setActiveTab] = useState("hours");
	useEffect(() => {
		if (!!discountsList.length) {
			setActiveTab("sales");
		}
	}, [discountsList]);
	const [isTabsOpened, setIsTabsOpened] = useState(
		isInIframe || isWidget ? false : true,
	);
	const { collectAnalytics } = useContext(AnalyticsContext);
	useEffect(() => onChange(activeTab), [activeTab]);

	const checkTab = val => {
		if (val === activeTab) {
			setIsTabsOpened(!isTabsOpened);
		} else {
			setIsTabsOpened(true);
		}
	};

	const orderedTabs = tabs.length > 0 && [
		tabs.find(t => t.value === "general"),
		tabs.find(t => t.value === "sales"),
		...tabs.filter(t => t.value !== "general" && t.value !== "sales"),
	];

	return (
		<div>
			<div className="border-b border-gray-200">
				<nav className="-mb-px flex" aria-label="Tabs">
					{orderedTabs?.map(
						(tab, i) =>
							tab && (
								<div
									key={i}
									onClick={() => {
										collectAnalytics(`${"store_tab" + tab.value}`);
										setActiveTab(tab.value);
										checkTab(tab.value);
									}}
									className={`py-2 w-full text-center border-b-2 font-medium text-sm cursor-pointer ${
										tab.value === activeTab && isTabsOpened
											? "border-emerald-500 text-emerald-600"
											: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
									}`}
								>
									<div className="flex justify-center items-center">
										<div className="flex justify-center items-center">
											<ChevronLeftIcon
												className={classNames(
													"w-4 h-4 mt-[1px]  cursor-pointer transition-all",
													tab.value === activeTab && isTabsOpened
														? "-rotate-90 text-emerald-400 hover:text-emerald-600"
														: "rotate-0 text-jane-400 hover:text-jane-600",
												)}
											/>
										</div>
										<span className="text-xs xs:text-base">{tab.label}</span>
									</div>
								</div>
							),
					)}
				</nav>
			</div>
			<div className={`${isTabsOpened ? "block" : "hidden"}`}>{children}</div>
		</div>
	);
}

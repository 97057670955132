import React, { useEffect, useState } from "react";
import { ShopHeader } from "./ShopHeader";
import { ShopGeneral } from "./ShopGeneral";
import {
	ClockIcon,
	InformationCircleIcon,
	TruckIcon,
} from "@heroicons/react/outline";
import { ShopTabsMenu } from "./ShopTabsMenu";
import { OpeningHoursDisplay } from "components/form/OpeningHoursDisplay";
import { SingleStoreDelivery } from "./SingleStoreDelivery";
import { useApi } from "components/hooks/useApi";
import { WBO } from "util/types";
import useWindowDimensions from "components/hooks/useWindowDimensions";
import DiscountSlider from "./DiscountSlider";
import { useIsInIframe } from "components/hooks/useIsInIframe";

export const SingleStoreHeader = ({ store, isWidget }) => {
	const api = useApi();
	const [activeTab, setActiveTab] = useState("hours");
	const [discountsList, setDiscountsList] = useState([]);
	const { width } = useWindowDimensions();
	const isInIframe = useIsInIframe();
	useEffect(() => {
		const getStoreDiscounts = async storeId => {
			const res = await api.getFullStoreDiscounts(storeId);
			if (res.ok) {
				const discounts = res.data.items;
				setDiscountsList(discounts);
				setActiveTab(discounts.length > 0 ? "sales" : "hours");
			}
		};
		if (store?.id) {
			getStoreDiscounts(store.id);
		}
	}, [store]);

	return (
		<div className="flex flex-col gap-6">
			<div className="bg-white shadow rounded-md">
				{!isInIframe && <ShopHeader store={store} isWidget={isWidget} />}
				<div className="flex flex-col lg:hidden w-full">
					{!isInIframe && (
						<div className=" px-4 py-1 ">
							{(width > WBO["md"] || (width < WBO["md"] && !isWidget)) && (
								<ShopGeneral store={store} />
							)}
						</div>
					)}

					<div>
						<ShopTabsMenu
							onChange={t => setActiveTab(t)}
							store={store}
							discountsList={discountsList}
							isWidget={isWidget}
						>
							<div className={`py-3  ${isWidget ? "px-3" : "px-1"} border-b `}>
								{(isWidget || isInIframe) && activeTab === "general" && (
									<ShopGeneral store={store} isWidget={isWidget} />
								)}
								{activeTab === "hours" && (
									<OpeningHoursDisplay
										val={store?.opening_hours}
										className="text-sm lg:text-base"
									/>
								)}
								{activeTab === "sales" && discountsList.length > 0 && (
									<DiscountSlider
										discountsList={discountsList}
										store={store}
										isTab
									/>
								)}
								{store?.has_delivery && activeTab === "delivery" && (
									<SingleStoreDelivery store={store} />
								)}
							</div>
						</ShopTabsMenu>
					</div>
				</div>
				<div className="w-full hidden lg:flex justify-around px-2 md:px-4 py-3">
					<div className="flex w-full">
						<div className="flex flex-col w-full">
							<div className="text-lg  font-semibold text-jane-500 flex items-center gap-1 w-full border-b-2 mb-2">
								<InformationCircleIcon className="w-4 h-4 text-gray-500 ml-1 mt-[1px]" />
								<span className="">מידע כללי</span>
							</div>
							<div>
								<ShopGeneral store={store} />
							</div>
						</div>
					</div>
					<div className="flex w-full">
						<div className="flex flex-col w-full">
							<div className="md:text-base lg:text-lg  font-semibold text-jane-500 flex items-center gap-1 w-full border-b-2 mb-2">
								<ClockIcon className="w-4 h-4 text-gray-500 ml-1 mt-[1px]" />
								שעות פתיחה
							</div>
							<OpeningHoursDisplay
								val={store?.opening_hours}
								className="text-sm lg:text-base"
							/>
						</div>
					</div>
					{store?.has_delivery && (
						<div className="flex w-full">
							<div className="flex flex-col w-full">
								<div className="md:text-base lg:text-lg  font-semibold text-jane-500 flex items-center gap-1 w-full border-b-2 mb-2">
									<TruckIcon className="w-4 h-4 text-gray-500 ml-1 mt-[1px]" />
									משלוחים
								</div>
								<SingleStoreDelivery store={store} />
							</div>
						</div>
					)}
				</div>
			</div>
			<DiscountSlider discountsList={discountsList} store={store} />
		</div>
	);
};

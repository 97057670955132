import { ClockIcon, PhotographIcon } from "@heroicons/react/outline";
import { DateTime } from "components/datetime/DateTime";
import { useIsInIframe } from "components/hooks/useIsInIframe";
import useWindowDimensions from "components/hooks/useWindowDimensions";
import ShareButton from "components/ui/ShareButton";
import WishlistToggle from "components/wishlist/WishlistToggle";
import { getToHour, logoAlt } from "util/common";
import { WBO } from "util/types";

export function ShopHeader({ store, isWidget }) {
	const { width } = useWindowDimensions();
	const isInIframe = useIsInIframe();
	if (!store) {
		return null;
	}

	const shouldHideImages = isWidget && width < WBO["md"];

	return (
		<div className="flex items-center">
			<div className="w-full relative">
				<div
					className={`w-full rounded-t-md overflow-hidden ${
						shouldHideImages && "hidden"
					}`}
				>
					{!!store.cover_img_url ? (
						<img
							src={store.cover_img_url}
							className="w-full h-[175px] md:h-[225px] lg:h-[325px] object-cover"
							alt={store.display_name}
						/>
					) : (
						<div className="w-full rounded-t-md overflow-hidden h-[175px] md:h-[225px] lg:h-[325px] flex justify-center items-center bg-gray-50">
							<PhotographIcon className="w-[36px] lg:w-[48px] h-[36px] lg:h-[48px] text-gray-300" />
						</div>
					)}
				</div>
				<div
					className={`absolute top-[118px] md:top-40 lg:top-60 right-2 md:right-4 lg:right-6 rounded-full shadow-md ${
						shouldHideImages && "hidden"
					}`}
				>
					{!!store?.logo_url ? (
						<img
							src={store.logo_url}
							alt={logoAlt(store)}
							className="w-28 h-28 md:w-32 md:h-32 lg:w-40 lg:h-40 rounded-full object-cover"
						/>
					) : (
						<div className="w-28 h-28 md:w-32 md:h-32 lg:w-40 lg:h-40 rounded-full flex justify-center items-center bg-gray-300">
							<PhotographIcon className="w-[36px] lg:w-[48px] h-[36px] lg:h-[48px] text-gray-50" />
						</div>
					)}
				</div>
				<div
					className={`w-full justify-end items-center gap-2 p-1 px-2 md:px-4 flex ${
						isWidget && "hidden md:flex"
					}`}
				>
					<WishlistToggle
						type="store"
						wishlistItemId={+store.id}
						count={store.wishlist_count}
					/>
					<ShareButton title={store.display_name} text={store.display_name} />
				</div>

				<div
					className={`
					flex gap-2
					${
						shouldHideImages
							? `mt-2 ${!isInIframe && "px-3"}`
							: "mt-16 px-2 md:px-4"
					} md:mt-20 lg:mt-[90px]`}
				>
					<div className="flex flex-col justify-between w-full relative">
						<p className="text-3xl font-bold">{store.display_name}</p>
						<div
							className={`flex items-center font-medium text-sm ${
								store.is_currently_open ? "text-jane-600" : "text-gray-500"
							}`}
						>
							<div className="flex items-center font-semibold text-[14px]">
								<span className="flex items-center">
									<ClockIcon className="w-3.5 h-3.5 text-gray-500 ml-1 mt-[1px]" />
									<span>
										{!!store.is_currently_open ? (
											<div className="text-green-500 ">פתוח</div>
										) : (
											<div className="text-red-500">סגור</div>
										)}
									</span>
									&nbsp;⋅&nbsp;
									<span className="text-gray-400">{getToHour(store)}</span>
								</span>
							</div>
						</div>

						{!!store.stock_updated_at && (
							<div className="font-semibold text-gray-500 py-1 text-sm md:text-base">
								עדכון אחרון&nbsp;
								<DateTime value={store.stock_updated_at} timeago />{" "}
								&nbsp;|&nbsp;
								<DateTime value={store.stock_updated_at} short />
							</div>
						)}
						<div className="lg:text-base pt-1 pb-2">{store.custom_message}</div>
					</div>

					<div className={`${!isWidget && "hidden"} md:hidden`}>
						{!!store.logo_url ? (
							<img
								src={store.logo_url}
								alt={logoAlt(store)}
								className="max-w-20 max-h-20 min-w-20 min-h-20 rounded-full object-cover border-[1px] border-gray-300 shadow"
							/>
						) : (
							<div className="w-20 h-20 md:w-32 md:h-32 lg:w-40 lg:h-40 rounded-full flex justify-center items-center bg-gray-300">
								<PhotographIcon className="w-[36px] lg:w-[48px] h-[36px] lg:h-[48px] text-gray-50" />
							</div>
						)}
						<div className="w-full flex justify-end items-center gap-2 p-1 text-base font-semibold">
							<WishlistToggle
								type="store"
								wishlistItemId={+store.id}
								count={store.wishlist_count}
							/>
							<ShareButton
								title={store.display_name}
								text={store.display_name}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

import React from "react";
import { classNames } from "util/common";

const CLOSED = "סגור";
const allDays = ["1", "2", "3", "4", "5", "6", "7"];

export function OpeningHoursDisplay({ val, slim = false, className = "" }) {
	const valWorkHours = val?.workHours || {};
	const fillMissingDays = data => {
		allDays.forEach(day => {
			if (!data.hasOwnProperty(day)) {
				data[day] = ["closed"];
			}
		});

		return data;
	};

	const filledData = fillMissingDays(valWorkHours);

	const openingHours = Object.entries(filledData).reduce((acc, entry) => {
		const newCurrentDay = entry[1];
		const currentDay = entry[0];
		const currentHour =
			newCurrentDay[0] !== "closed"
				? entry[1].reduce((acc, { fromHour, toHour }) => {
						if (acc) acc += ",";
						acc += fromHour + "-" + toHour;
						return acc;
				  }, "")
				: CLOSED;
		const lastHour = acc[acc.length - 1];

		if (lastHour && currentHour === lastHour.currentHour) {
			lastHour.toDay = currentDay;
		} else {
			acc.push({
				currentDay,
				currentHour,
			});
		}
		return acc;
	}, []);
	return openingHours.map(({ currentDay, toDay, currentHour }) => {
		return (
			<div key={currentDay} className="flex justify-between text-sm">
				<div className={`w-1/5 ${className}`}>
					{dayName[currentDay]}
					{toDay && `-${dayName[toDay]}`}
					{": "}
				</div>
				<div className={`w-4/5 flex flex-col ${className}`}>
					{currentHour.split(",").map(text => (
						<span key={text}>{text}</span>
					))}
				</div>
			</div>
		);
	});
}
const dayName = {
	1: "א׳",
	2: "ב׳",
	3: "ג׳",
	4: "ד׳",
	5: "ה׳",
	6: "ו׳",
	7: "ש׳",
};

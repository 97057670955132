import { HeartIcon } from "@heroicons/react/outline";
import { HeartIcon as HeartIconFilled } from "@heroicons/react/solid";
import { AuthContext } from "components/contexts/AuthContext";
import { useApi } from "components/hooks/useApi";
import { LoginBoxModal } from "components/modals/LoginBoxModal";
import { debounce } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { toastMessage } from "util/common";

const WishlistToggle = ({
	showCount = true,
	count: defaultCount = 0,
	type = "product",
	wishlistItemId,
	className,
}) => {
	const { authenticated, wishlist, setWishlist } = useContext(AuthContext);
	const api = useApi();
	const wishlistItemsIds = wishlist?.map(wishlistItem => {
		if (type === "product") {
			return wishlistItem.product_id;
		} else {
			return wishlistItem.store_id;
		}
	});

	const [count, setCount] = useState(defaultCount);
	const [isInWishlist, setIsInWishlist] = useState(
		wishlistItemsIds?.includes(wishlistItemId),
	);

	useEffect(() => {
		if (authenticated) {
			setIsInWishlist(wishlistItemsIds?.includes(wishlistItemId));
		}
	}, [authenticated]);

	const onToggle = useCallback(
		debounce(async () => {
			setIsInWishlist(prev => !prev);
			const key = type === "product" ? "product_id" : "store_id";

			if (isInWishlist) {
				const res = await api.removeFromWishlist({
					[key]: wishlistItemId,
				});

				if (res.ok) {
					toastMessage(
						`${type === "product" ? "מוצר" : "בית מרקחת"} הוסר`,
						"success",
					);
					setWishlist(wishlist =>
						wishlist.filter(wishlistItem => {
							if (type === "product") {
								return wishlistItem.product_id !== wishlistItemId;
							} else {
								return wishlistItem.store_id !== wishlistItemId;
							}
						}),
					);
					setCount(count => count - 1);
				} else {
					toastMessage("שגיאה בהסרת מוצר", "error");
				}
			} else {
				const res = await api.addToWishlist({
					[key]: wishlistItemId,
				});

				if (res.ok) {
					toastMessage(
						`${type === "product" ? "מוצר" : "בית מרקחת"} נוסף`,
						"success",
					);
					setWishlist(wishlist => [...wishlist, { [key]: wishlistItemId }]);
					setCount(count => count + 1);
				} else {
					toastMessage("שגיאה בהוספת מוצר", "error");
				}
			}
		}, 200),
		[isInWishlist],
	);

	if (!authenticated) {
		return (
			<LoginBoxModal
				activator={
					<div className="flex items-center gap-1">
						<div className="cursor-pointer relative w-6 h-6">
							<HeartIcon className={`w-6 h-6${className}`} />
						</div>
						{showCount && <span>{count}</span>}
					</div>
				}
			/>
		);
	}

	return (
		<div className="flex items-center gap-1">
			<div onClick={onToggle} className="cursor-pointer relative w-6 h-6">
				<HeartIconFilled
					className={`w-6 h-6 absolute inset-0 transition-all duration-300 ease-out ${
						isInWishlist
							? "opacity-100 scale-100 text-red-500"
							: "opacity-0 scale-75"
					} ${className}`}
				/>
				<HeartIcon
					className={`w-6 h-6 absolute inset-0 transition-all duration-300 ease-out ${
						isInWishlist ? "opacity-0 scale-75" : "opacity-100 scale-100"
					} ${className}`}
				/>
			</div>
			{showCount && <span>{count}</span>}
		</div>
	);
};

export default WishlistToggle;

import { useApi } from "components/hooks/useApi";
import { ModalDialog } from "components/modals/ModalDialog";
import ProductViewModal from "components/modals/ProductViewModal";
import { ProductsTableHiddenRow } from "components/products/ProductsTableHiddenRow";
import { useEffect, useRef, useState } from "react";
import { dateConvert, toastMessage } from "util/common";

const translate = {
	buy: "קנה",
	get: "וקבל",
	getNoAnd: "קבל",
	pay: "ושלם",
	in: "ב",
	free: "חינם",
	discounted: "הנחה",
	all_items: "כל המוצרים",
	units: "יח׳",
	buying: "ברכישת",
	buyingWithH: "ברכישה",
	and: "ו",
};

const ProductList = ({
	restrictionOrReceiveItem,
	store,
	setModalViewProduct,
}) => {
	const api = useApi();
	if (restrictionOrReceiveItem.type === "all_items") return;
	const products = restrictionOrReceiveItem.heb_names;
	const productIds = restrictionOrReceiveItem.applied_on_items;
	const handleProductClick = async productId => {
		const res = await api.getWidgetStoreProducts(store.id, {
			product_id: productId,
		});
		if (res.ok && !!res.data?.items?.id) {
			setModalViewProduct({ isOpen: true, item: res.data.items, history: [] });
		} else {
			toastMessage("המוצר לא נמצא במלאי", "info");
		}
	};
	return (
		<div className="text-base">
			<span>- </span>
			{products.map((product, i) => {
				return (
					<>
						<span
							key={i}
							onClick={() => handleProductClick(productIds[i])}
							className="cursor-pointer hover:underline"
						>
							{product}
						</span>
						{i === products.length - 1 ? "" : ", "}
					</>
				);
			})}
		</div>
	);
};

const DiscountCard = ({ discount }) => {
	const [modalViewProduct, setModalViewProduct] = useState({
		isOpen: false,
		item: null,
		history: [],
	});

	const {
		required_items_count: requiredItemCount,
		applied_on_items_count: appliedOnItemsCount,
		discount_type: discountType,
		type: type,
		discount_amount: discountAmount,
		restriction: restriction,
		receive_item: receiveItem,
		quantity_threshold: quantityThreshold,
		price_threshold: priceThreshold,
		store,
	} = discount;
	const [isOpen, setIsOpen] = useState(false);
	const [selectedDiscount, setSelectedDiscount] = useState(null);
	const ref = useRef();
	const [currHeight, setCurrHeight] = useState(0);

	useEffect(() => {
		if (ref?.current?.scrollHeight) {
			setCurrHeight(ref?.current?.scrollHeight);
		}
	}, []);

	const handleResize = () => {
		if (ref?.current?.scrollHeight) {
			setCurrHeight(ref?.current?.scrollHeight);
		}
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const dates = `${dateConvert(discount.start_date)} - ${dateConvert(
		discount.end_date,
	)}`;

	const isOneTimeDiscount = discount.one_time_discount
		? "ללא כפל מבצעים"
		: "יש כפל מבצעים";

	const isEntireStoreDiscountNoLimit = !priceThreshold && !quantityThreshold;

	const entireStoreDiscountThreshold = () => {
		if (priceThreshold) {
			return `${translate["buy"]} ${translate["in"]}-₪${priceThreshold},`;
		} else if (quantityThreshold) {
			return `${translate["buy"]} ${quantityThreshold} ${translate["units"]}`;
		} else {
		}
	};

	const getDiscountType = (
		type,
		discountType,
		discountAmount,
		restrictionOrReceiveItem = null,
	) => {
		let isAllProductsRes = null;
		if (restrictionOrReceiveItem) {
			isAllProductsRes = isAllProducts(restrictionOrReceiveItem);
		}
		switch (discountType) {
			case "free":
				return "בחינם";
			case "percentage":
				if (type === "buy_x_get_y") {
					return `${translate["in"]}${discountAmount}% ${translate["discounted"]}`;
				} else {
					return `${
						isEntireStoreDiscountNoLimit
							? translate["getNoAnd"]
							: translate["get"]
					} ${discountAmount}% ${translate["discounted"]} ${
						isAllProductsRes === ":"
							? translate["buying"]
							: translate["buyingWithH"]
					}${isAllProductsRes}`;
				}
			case "amount":
				if (type === "buy_x_get_y") {
					return `${translate["in"]}₪${discountAmount} ${translate["discounted"]}`;
				} else {
					return `${
						isEntireStoreDiscountNoLimit
							? translate["getNoAnd"]
							: translate["get"]
					}
                    ₪${discountAmount} ${translate["discounted"]} ${
						isAllProductsRes === ":"
							? translate["buying"]
							: translate["buyingWithH"]
					}${isAllProductsRes}`;
				}
			case "final_amount":
				if (type === "buy_x_get_y") {
					return `${translate["in"]}₪${discountAmount}`;
				} else {
					return `${translate["pay"]} ₪${discountAmount} ${
						isAllProductsRes === ":"
							? translate["buying"]
							: translate["buyingWithH"]
					}${isAllProductsRes}`;
				}
		}
	};

	const isAllProducts = restrictionOrReceiveItem => {
		if (restrictionOrReceiveItem.type === "all_items") {
			return " מכל המוצרים";
		} else {
			return ":";
		}
	};

	const getRowHiddenContent = (row, setModalViewProduct = () => {}) => {
		return (
			<ProductsTableHiddenRow
				row={row}
				withCartButton={true}
				enableOrders={store?.enable_orders}
				is48HoursPast={store?.hours_past_48}
				isSingleStore={true}
				setModalViewProduct={setModalViewProduct}
			/>
		);
	};

	return (
		<>
			<ProductViewModal
				modalViewProduct={modalViewProduct}
				setModalViewProduct={setModalViewProduct}
				getRowHiddenContent={getRowHiddenContent}
			/>
			{isOpen && (
				<ModalDialog
					isOpen={isOpen}
					showSubmitButton={false}
					cancelBtnText="חזרה"
					title={selectedDiscount.title}
					onClose={() => {
						setIsOpen(false);
						setSelectedDiscount(null);
					}}
					closeOnClickOutside={true}
				>
					<div className="flex justify-center">
						<span className="whitespace-pre-line text-center">
							{selectedDiscount.description}
						</span>
					</div>
				</ModalDialog>
			)}

			<div className="flex flex-col justify-between h-full">
				<div
					className="flex flex-col mt-1.5 relative overflow-hidden h-[185px]"
					ref={ref}
				>
					{type === "buy_x_get_y" ? (
						<div className="flex flex-col px-2 text-center h-full items-center justify-center">
							<div className="flex flex-col items-center text-center">
								<div className="font-semibold text-base">
									{translate["buy"]} {requiredItemCount}
									{isAllProducts(restriction)}
								</div>
								<div className="text-base px-2">
									<ProductList
										restrictionOrReceiveItem={restriction}
										store={store}
										setModalViewProduct={setModalViewProduct}
									/>
								</div>
							</div>
							<div className="flex flex-col items-center text-center">
								<div className="font-semibold text-base">
									{translate["get"]} {appliedOnItemsCount}{" "}
									{getDiscountType(type, discountType, discountAmount)}
									{isAllProducts(receiveItem)}
								</div>
								<div className="text-base px-2">
									<ProductList
										restrictionOrReceiveItem={receiveItem}
										store={store}
										setModalViewProduct={setModalViewProduct}
									/>
								</div>
							</div>
						</div>
					) : (
						<div className="flex flex-col px-2 text-center h-full items-center justify-center">
							<div className="font-semibold text-base">
								{entireStoreDiscountThreshold()}{" "}
								{getDiscountType(
									type,
									discountType,
									discountAmount,
									receiveItem,
								)}
							</div>
							<div className="text-base px-2">
								<ProductList
									restrictionOrReceiveItem={receiveItem}
									store={store}
									setModalViewProduct={setModalViewProduct}
								/>
							</div>
						</div>
					)}
					{currHeight > 185 && (
						<div
							className="absolute bottom-0 bg-gradient-to-t from-white via-white to-transparent w-full h-7 text-xs text-center flex flex-col justify-end underline font-semibold text-jane-800"
							onClick={() => {
								setSelectedDiscount(discount);
								setIsOpen(true);
							}}
						>
							קרא עוד...
						</div>
					)}
				</div>
				<div
					className={`flex items-center text-sm justify-between px-2 bg-jane-300 rounded-b-lg font-semibold`}
				>
					<div className="whitespace-nowrap">{isOneTimeDiscount}</div>
					<div className="whitespace-nowrap">{dates}</div>
				</div>
			</div>
		</>
	);
};

export default DiscountCard;

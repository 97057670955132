import { LinkIcon, ShareIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import { Drawer } from "vaul";
import { isMobile } from "react-device-detect";
import { toastMessage } from "util/common";
import { MdOutlineIosShare } from "react-icons/md";
import { Button } from "components/form/Button";
import { useIsInIframe } from "components/hooks/useIsInIframe";

function ShareButton({ className = "", title = "", text = "" }) {
	const isInIframe = useIsInIframe();
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const copyToClipboard = async () => {
		navigator.clipboard
			.writeText(window.location.href)
			.then(() => {
				setIsDrawerOpen(false);
				toastMessage("הקישור הועתק", "success");
			})
			.catch(() => {
				// Silently ignore errors
			});
	};

	const handleShare = async () => {
		setIsDrawerOpen(false);
		if (navigator.share) {
			await navigator
				.share({
					title: title,
					text: text,
					url: window.location.href,
				})
				.catch(() => {
					// Silently ignore errors
				});
		}
	};

	return (
		<>
			{isMobile && !isInIframe ? (
				<Drawer.Root
					shouldScaleBackground
					open={isDrawerOpen}
					onOpenChange={isOpen => setIsDrawerOpen(isOpen)}
				>
					<Drawer.Trigger asChild>
						<button
							// onClick={handleClick}
							className={
								"text-jane-800 bg-white w-fit rounded-md transition-all" +
								className
							}
						>
							<span className="flex gap-1 items-center">
								<ShareIcon className="h-6 w-6" />
							</span>
						</button>
					</Drawer.Trigger>
					<Drawer.Portal>
						<Drawer.Overlay className="fixed inset-0 z-50 bg-black/40" />
						<Drawer.Content className="bg-white flex z-[999] flex-col pb-12 px-4 rounded-t-[10px] h-fit fixed bottom-0 left-0 right-0">
							<Drawer.Handle className="w-10 h-1 my-4 bg-jane-500" />
							<div className="flex flex-col gap-2">
								<Button
									className="gap-2 items-center"
									onClick={() => copyToClipboard()}
								>
									<LinkIcon className="w-6 h-6" />
									<span className="text-base">העתקת קישור</span>
								</Button>
								<Button
									className="gap-2 items-center"
									onClick={() => handleShare()}
								>
									<MdOutlineIosShare className="w-6 h-6" />
									<span className="text-base">שיתוף באמצעות...</span>
								</Button>
							</div>
						</Drawer.Content>
					</Drawer.Portal>
				</Drawer.Root>
			) : (
				<button
					onClick={() => copyToClipboard()}
					className={
						"text-jane-800  bg-white w-fit rounded-md transition-all" +
						className
					}
				>
					<span className="flex gap-1 items-center">
						<ShareIcon className="h-6 w-6 " />
					</span>
				</button>
			)}
		</>
	);
}

export default ShareButton;

import moment from "moment";

export function DateTime({
	value,
	format = "D/M/YY, HH:mm",
	timeago = false,
	short = false,
	oneLine = false,
}) {
	const valFmt = moment(value).format(format);
	let valAgo;
	try {
		valAgo =
			"לפני " +
			moment(value)
				.fromNow(true)
				.replaceAll("an ", "")
				.replaceAll("a ", "")
				.replaceAll("few", "כמה")
				.replaceAll("seconds", "שניות")
				.replaceAll("second", "שניה")
				.replaceAll("minutes", "דקות")
				.replaceAll("minute", "דקה")
				.replaceAll("hours", "שעות")
				.replaceAll("hour", "שעה")
				.replaceAll("days", "ימים")
				.replaceAll("day", "יום")
				.replaceAll("weeks", "שבועות")
				.replaceAll("week", "שבוע")
				.replaceAll("months", "חודשים")
				.replaceAll("month", "חודש")
				.replaceAll("years", "שנים")
				.replaceAll("year", "שנה");
	} catch (e) {
		valAgo = "לפני " + moment(value)?.fromNow(true);
	}

	if (!!timeago) {
		return (
			<span dir="ltr" title={valFmt}>
				{valAgo}
			</span>
		);
	}
	if (!!short) {
		return (
			<span dir="ltr" title={valAgo}>
				{valFmt}
			</span>
		);
	}
	if (!!oneLine) {
		return (
			<div className="flex flex-wrap items-center">
				<div className="ml-1">{valFmt}</div>
				<div className="text-gray-400 text-sm">({valAgo})</div>
			</div>
		);
	}
	return (
		<div className="inline-block">
			<div>{valFmt}</div>
			<div className="text-gray-400  text-base 2xs:text-xs xs:text-xs sm:text-base">
				{valAgo}
			</div>
		</div>
	);
}
